import React, { memo, useContext } from 'react';
import { string } from 'prop-types';
import { useDataModel } from '@thd-nucleus/data-sources';
import { AppContext } from '@thd-nucleus/app-render';
import { useLocation, useParams } from '@thd-olt-component-react/router';
import { useContentfulLiveUpdates } from '@contentful/live-preview/react';
import { NoIndexNoFollowMetadata, Metadata } from '@thd-olt-component-react/metadata';
import { previewDataModel } from '../../contentful/dataModel/previewDataModel';
import { EducationalLayoutRenderer } from '../../contentful/templates/EducationalLayoutRenderer';
import { NavigationalLayoutRenderer } from '../../contentful/templates/NavigationalLayoutRenderer';
import { InspirationalLayoutRenderer } from '../../contentful/templates/InspirationalLayoutRenderer';
import { FlexibleLayoutRenderer } from '../../contentful/templates/FlexibleLayoutRenderer';
import {
  formatCiaData,
  formatContentfulData,
  getLayoutClass,
  isEmpty,
  LayoutTypes
} from '../utils/previewUtils';

export const LayoutContainer = memo(({ ...props }) => {
  const { instance } = useContext(AppContext);
  let customerType = instance?.customer?.type;

  const { LayoutId, LayoutTypeID, TestPageId } = useParams();

  let layoutQueryName = '';
  let Renderer = ';';

  switch (LayoutTypeID) {
  case LayoutTypes.Educational:
    layoutQueryName = 'educationalPreview';
    Renderer = EducationalLayoutRenderer;
    break;
  case LayoutTypes.Navigational:
    layoutQueryName = 'navigationalPreview';
    Renderer = NavigationalLayoutRenderer;
    break;
  case LayoutTypes.Inspirational:
    layoutQueryName = 'inspirationalPreview';
    Renderer = InspirationalLayoutRenderer;
    break;
  default:
    layoutQueryName = 'flexiblePreview';
    Renderer = FlexibleLayoutRenderer;
    break;
  }

  const { data, loading, error } = useDataModel(layoutQueryName, {
    variables: {
      id: LayoutId,
      layoutClass: getLayoutClass(LayoutTypeID),
    },
  });

  // useHelmet('landing-page', { data }, imagePreloader, [data]);

  const formattedData = formatCiaData(data);
  const updatedFields = useContentfulLiveUpdates(formattedData);

  if (!data || loading || error) {
    return null;
  }

  const livePreviewData = formatContentfulData(!isEmpty(updatedFields) ? updatedFields : null);
  const renderingData = livePreviewData || data;

  const fakeLayoutsData = {
    layouts: {
      content: renderingData?.layout,
    }
  };

  return (
    <>
      <Metadata>
        <NoIndexNoFollowMetadata />
      </Metadata>
      <Renderer
        data={fakeLayoutsData}
        loading={loading}
        pageId={TestPageId || ''}
        storeId={props?.storeId}
        customerType={customerType}
        pageTypeComponents={{
          landingPageBreadcrumbs: null,
          landingPageMetadata: null
        }}
        isPreviewLink
        isLivePreview
      />
    </>
  );
});

LayoutContainer.dataModel = previewDataModel;

LayoutContainer.displayName = 'LayoutContainer';

LayoutContainer.defaultProps = {};

LayoutContainer.propTypes = {
  storeId: string.isRequired,
};
